<template>
  <div>
    <div class="pb-6 pt-[84px] px-6 w-full h-screen bg-[#F7F7FC]">
      <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-lg">
        <div class="flex flex-col justify-between h-full">
          <div
            class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <BioLinksFiltersV2></BioLinksFiltersV2>
            <BioLinksTableV2></BioLinksTableV2>
            <DeleteBioLinkV2></DeleteBioLinkV2>
          </div>
          <BioLinksPaginationV2></BioLinksPaginationV2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    BioLinksPaginationV2: () => import('@/views/pages/bio-link/dialogs/BioLinksPaginationV2.vue'),
    BioLinksFiltersV2: () => import('@/views/pages/bio-link/BioLinksFiltersV2.vue'),
    BioLinksTableV2: () => import('@/views/pages/bio-link/BioLinksTableV2.vue'),
    DeleteBioLinkV2: () => import('@/views/pages/bio-link/dialogs/DeleteBioLinkV2.vue')
  },
  mounted() {
    if (!this.getProfile.triggers.bio_links_onboarding) {
      setTimeout(() => {
        this.$bvModal.show('bioLinkOnBoarding')
      }, 500)
    }
  }
}
</script>
<style lang="less">
.bio_input_search {
  max-width: 400px !important;
  width: 400px !important;
}

.bio_status {
  padding: 2px 8px;
  margin-left: 5px;
  border-radius: 4px;
  color: #fff;
}

.status_incomplete {
  background: #ffc107;
}

.status_complete {
  background: #11b50f;
}

.replug_link_generated {
  cursor: pointer;
}

.truncate {
  max-width: 195px;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy_link {
  margin-left: 13px;
  display: none;
}

.modal-bio-preview .modal-dialog {
  max-width: 390px;
}

@-moz-document url-prefix() {
  .default_style_dropdown .dropdown-menu li.list_item a {
    font-size: 13px;
  }
}

.favicon_img {
  width: 20px !important;
  height: 20px !important;
}
</style>
